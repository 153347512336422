import { render, staticRenderFns } from "./info-verify.vue?vue&type=template&id=296d6fc5&scoped=true"
import script from "./info-verify.vue?vue&type=script&lang=js"
export * from "./info-verify.vue?vue&type=script&lang=js"
import style0 from "./info-verify.vue?vue&type=style&index=0&id=296d6fc5&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296d6fc5",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/bium-ops-web_2zId/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('296d6fc5')) {
      api.createRecord('296d6fc5', component.options)
    } else {
      api.reload('296d6fc5', component.options)
    }
    module.hot.accept("./info-verify.vue?vue&type=template&id=296d6fc5&scoped=true", function () {
      api.rerender('296d6fc5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/credit/creditReport/components/info-verify.vue"
export default component.exports