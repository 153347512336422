var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "anti-fraud" }, [
    _c("div", { staticClass: "sec-box fraud-1" }, [
      _c("p", { staticClass: "desc" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("dealNull")(_vm.moduleInfos.fraudDesc)) +
            "\n    "
        ),
      ]),
      _c("p", [_vm._v("当前反欺诈评估建议")]),
    ]),
    _c(
      "div",
      { staticClass: "fraud-2" },
      _vm._l(_vm.moduleInfos.cardList, function (card, idx) {
        return _c(
          "div",
          { key: `antiCard-${idx}`, staticClass: "sec-box info-card" },
          [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm._f("dealNull")(card.name))),
            ]),
            _c("p", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm._f("dealNull")(card.desc))),
            ]),
            _c("p", { staticClass: "value", class: card.statusClass }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("dealNull")(card.value)) +
                  "\n      "
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }