export const RiskResultInfos = {
  resDesc: "",
  resClass: "",
  numDatas: [
    { label: "店铺授信额度", value: '', unit: "万元" },
    { label: "首次支用折扣率", value: '', unit: "%" },
    { label: "多次支用折扣率", value: '', unit: "%" },
  ],
};

export const VerifyResInfos = [
  {
    icon: "el-icon-postcard",
    labelName1: "身份信息验证",
    labelName2: "（检验身份证号码，姓名一致性）",
    verifyDesc: "",
    statusClass: "",
  },
  {
    icon: "el-icon-mobile-phone",
    labelName1: "手机实名认证",
    labelName2: "（检验身份证、姓名、手机号一致性）",
    verifyDesc: "",
    statusClass: "",
  },
  {
    icon: "el-icon-bank-card",
    labelName1: "银行卡信息验证",
    labelName2: "（检验身份证、姓名、银行卡一致性）",
    verifyDesc: "",
    statusClass: "",
  },
  {
    icon: "el-icon-user-solid",
    labelName1: "主账户权限检验",
    labelName2: "（检验店铺主账户权限是否符合要求）",
    verifyDesc: "",
    statusClass: "",
  },
  {
    icon: "el-icon-user",
    labelName1: "子账户权限检验",
    labelName2: "（检验子账户权限是否符合要求）",
    verifyDesc: "",
    statusClass: "",
  },
  {
    icon: "el-icon-lock",
    labelName1: "监管账户检验",
    labelName2: "（检验监管户是否换绑成功）",
    verifyDesc: "",
    statusClass: "",
  },
];

export const CreditScoreInfos = {
  score: 0,
  chartData: [],
}

export const CreditPortraitEntInfos = {
  score: 0,
  chartData: [],
  cardList: [
    { name: '企业贷前评分', desc: '企业贷前综合评估', value: '', unit: '分' },
    { name: '企业工商注册时长', desc: '企业经营时间评估', value: '', unit: '天' },
    { name: '经营场地类型', desc: '企业经营实力评估', value: '', unit: '' },
    { name: '法人年龄', desc: '法人年龄评估', value: '', unit: '岁' },
    { name: '法人婚姻状况', desc: '法人婚姻评估', value: '', unit: '' },
  ],
  judicialList: [],
}

export const CreditPortraitShopInfos = {
  score: 0,
  monthAveragePay: '',
  salesAmt: '',
  cardList: [
    { name: '店铺体验分', desc: '消费者店铺消费感受评估', value: '', unit: '分' },
    // { name: '店铺历史差评率', desc: '消费者店铺满意度评估', value: '', unit: '‰' },
    { name: '店铺历史差评率', desc: '消费者店铺满意度评估', value: '', unit: '%' },
    { name: '近7日店铺回款率', desc: '店铺回款能力评估', value: '', unit: '%' },
    { name: '近30日店铺佣金率', desc: '店铺活动成本评估', value: '', unit: '%' },
    { name: '近90天店铺揽件率', desc: '店铺物流健康度评估', value: '', unit: '%' },
    { name: '店铺逾期次数', desc: '店铺逾期记录次数', value: '', unit: '次' },
    { name: '店铺摊还次数', desc: '店铺摊还记录次数', value: '', unit: '次' },
    { name: '近90天店铺退货率', desc: '店铺发货后退款评估', value: '', unit: '%' },
    { name: '近30日店铺敞口覆盖率', desc: '店铺资金风险评估', value: '', unit: '%' },
    { name: '店铺经营类目', desc: '经营品类特质', value: '', unit: '' },
  ],
  lineChartData1: [],
  lineChartData2: [],
  lineChartData3: [],
  lineChartData4: [],
}

export const AntiFraudInfos = {
  fraudDesc: '',
  cardList: [
    { name: '疑似刷机风险判定', desc: '设备欺诈风险评估', value: '', statusClass: '' },
    { name: '多头借贷风险判定', desc: '近一个月多头借贷次数', value: '', statusClass: '' },
    { name: 'IP刷单风险判定', desc: 'IP刷单欺诈风险评估', value: '', statusClass: '' },
    { name: '虚假手机号码风险判定', desc: '手机号码欺诈风险评估', value: '', statusClass: '' },
  ],
}

export const RiskHitInfos = {
  preLoanList: [],
  inLoanList: [],
  afterLoanList: [],
}
