<template>
  <section class="anti-fraud">
    <div class="sec-box fraud-1">
      <p class="desc">
        {{ moduleInfos.fraudDesc | dealNull }}
      </p>
      <p>当前反欺诈评估建议</p>
    </div>

    <div class="fraud-2">
      <div
        class="sec-box info-card"
        v-for="(card, idx) in moduleInfos.cardList"
        :key="`antiCard-${idx}`"
      >
        <p class="name">{{ card.name | dealNull }}</p>
        <p class="desc">{{ card.desc | dealNull }}</p>
        <p class="value" :class="card.statusClass">
          {{ card.value | dealNull }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { dealNull } from "@/filters/index";

export default {
  name: "AntiFraud",
  filters: { dealNull },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.anti-fraud {
  .fraud-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    font-size: 16px;
    .desc {
      font-size: 30px;
      font-weight: 600;
      color: #29d;
    }
  }
  .fraud-2 {
    margin-top: 5px;
    .info-card {
      width: calc(25% - 10px);
    }
  }
}
</style>
