<template>
  <section class="credit-portrait-ent">
    <div class="portrait-1">
      <div class="sec-box portrait-lf">
        <div ref="chartDashboardRef" class="canvas-container"></div>
      </div>
      <div class="sec-box portrait-rg">
        <div ref="chartBarRef" class="canvas-container"></div>
      </div>
    </div>

    <div class="portrait-2">
      <div
        class="sec-box info-card"
        v-for="(card, idx1) in moduleInfos.cardList"
        :key="`card-${idx1}`"
      >
        <p class="name">{{ card.name | dealNull }}</p>
        <p class="desc">{{ card.desc | dealNull }}</p>
        <p class="value">
          {{ card.value | dealNull
          }}<span v-if="card.unit">{{ card.unit }}</span>
        </p>
      </div>
    </div>

    <div class="portrait-3">
      <ul class="sec-box ordinance-list">
        <li class="title">司法涉诉评估</li>
        <template
          v-if="moduleInfos.judicialList && moduleInfos.judicialList.length"
        >
          <li
            class="list-item"
            v-for="(judicial, idx2) in moduleInfos.judicialList"
            :key="`judicial-${idx2}`"
          >
            <label>{{ judicial.name | dealNull }}</label>
            <label>{{ judicial.remark | dealNull }}</label>
            <label class="warning" v-if="judicial.isHit"
              ><i class="el-icon-warning"></i>{{ judicial.decision }}</label
            >
            <label class="success" v-else
              ><i class="el-icon-success"></i>{{ judicial.decision }}</label
            >
          </li>
        </template>
        <li class="no-ordinance" v-else>暂无数据</li>
      </ul>
    </div>
  </section>
</template>

<script>
import echarts from "echarts";
import { dealNull, numberToCurrency } from "@/filters/index";
import { drawDashboard } from "../service/too";

export default {
  name: "CreditPortraitEnt",
  filters: { dealNull, numberToCurrency },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      chartOption: {},
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        // 左边-信誉分
        const { score } = this.moduleInfos;
        const value = score || 0;
        const ratio = value / 1000;
        const dataA = [{ value, name: "企业信用评分" }];
        drawDashboard(dataA, ratio, "", this.$refs.chartDashboardRef);
        // 右边-柱状体
        this.setChartOption();
        const dom = this.$refs.chartBarRef;
        echarts.init(dom).dispose(); // 创建之前销毁实例
        const Chart = echarts.init(dom);
        Chart.setOption(this.chartOption);
        window.addEventListener("resize", () => {
          Chart.resize();
        });
      });
    },

    // 设置echarts
    setChartOption() {
      const { chartData } = this.moduleInfos;
      if (chartData && chartData.length) {
        let [xAxisDatas, seriesData] = [[], []];
        for (let i = 0; i < chartData.length; i++) {
          xAxisDatas.push(chartData[i].label);
          seriesData.push(chartData[i].value);
        }
        // 配置图表参数
        this.chartOption = {
          tooltip: { show: true },
          grid: {
            top: "15%",
            right: "6%",
            left: "10%",
            bottom: "12%",
          },
          xAxis: [
            {
              type: "category",
              data: xAxisDatas,
              axisLine: {
                lineStyle: {
                  color: "#29d",
                },
              },
              axisLabel: {
                color: "#333",
                textStyle: {
                  fontSize: 14,
                },
              },
              axisTick: { show: false },
            },
          ],
          yAxis: [
            {
              axisLabel: {
                formatter: "{value}",
                color: "#333",
                fontSize: 14,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#29d",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#ccc",
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              type: "bar",
              data: seriesData,
              barWidth: "30%",
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0.3,
                        color: "#158dff", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#435ebe", // 100% 处的颜色
                      },
                    ],
                    false
                  ),
                  shadowColor: "rgba(0,244,255,1)",
                  shadowBlur: 4,
                },
              },
              label: {
                normal: {
                  show: true,
                  lineHeight: 10,
                  formatter: function (parmas) {
                    const { value, dataIndex } = parmas;
                    const unit = chartData[dataIndex].unit;
                    return unit ? value + unit : value;
                  },
                  position: "top",
                  textStyle: {
                    color: "#435ebe",
                    fontSize: 14,
                  },
                },
              },
            },
          ],
        };
      } else {
        this.chartOption = {
          id: "1",
          title: {
            text: "暂无数据",
            x: "center",
            y: "center",
            textStyle: {
              fontSize: 26,
              color: "#666",
              fontWeight: "normal",
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-portrait-ent {
  .portrait-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      height: 260px;
      font-size: 16px;
    }
    .portrait-lf {
      width: calc(45% - 10px);
      .canvas-container {
        width: 100%;
        height: 260px;
      }
    }
    .portrait-rg {
      width: 55%;
      .canvas-container {
        width: 90%;
        height: 260px;
        margin: 0 auto;
      }
    }
  }
  .portrait-2 {
    margin: 5px 0;
    .info-card {
      width: calc(20% - 10px);
    }
  }
}
</style>
