var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "credit-score" }, [
    _c("div", { staticClass: "sec-box score-lf" }, [
      _c("div", { ref: "chartDashboardRef", staticClass: "canvas-container" }),
    ]),
    _c("div", { staticClass: "sec-box score-rg" }, [
      _c("div", { ref: "chartRadarRef", staticClass: "canvas-container" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }