<template>
  <section class="risk-hit">
    <div class="hit-1">
      <ul class="sec-box ordinance-list">
        <li class="title">贷前规则集</li>
        <template v-if="moduleInfos.preLoanList && moduleInfos.preLoanList.length">
          <li class="list-item" v-for="(preLoan, idx1) in moduleInfos.preLoanList" :key="`preLoan-${idx1}`">
            <label>{{preLoan.name | dealNull}}</label>
            <label>{{preLoan.remark | dealNull}}</label>
            <label class="warning" v-if="preLoan.isHit"><i class="el-icon-warning"></i>{{preLoan.decision}}</label>
            <label class="success" v-else><i class="el-icon-success"></i>{{preLoan.decision}}</label>
          </li>
        </template>
        <li class="no-ordinance" v-else>暂无数据</li>
      </ul>
    </div>

    <div class="hit-2">
      <ul class="sec-box ordinance-list">
        <li class="title">贷中规则集</li>
        <template v-if="moduleInfos.inLoanList && moduleInfos.inLoanList.length">
          <li class="list-item" v-for="(inLoan, idx2) in moduleInfos.inLoanList" :key="`inLoan-${idx2}`">
            <label>{{inLoan.name | dealNull}}</label>
            <label>{{inLoan.remark | dealNull}}</label>
            <label class="warning" v-if="inLoan.isHit"><i class="el-icon-warning"></i>{{inLoan.decision}}</label>
            <label class="success" v-else><i class="el-icon-success"></i>{{inLoan.decision}}</label>
          </li>
        </template>
        <li class="no-ordinance" v-else>暂无数据</li>
      </ul>
    </div>

    <div class="hit-3">
      <ul class="sec-box ordinance-list">
        <li class="title">贷后规则集</li>
        <template v-if="moduleInfos.afterLoanList && moduleInfos.afterLoanList.length">
          <li class="list-item" v-for="(afterLoan, idx3) in moduleInfos.afterLoanList" :key="`afterLoan-${idx3}`">
            <label>{{afterLoan.name | dealNull}}</label>
            <label>{{afterLoan.remark | dealNull}}</label>
            <label class="warning" v-if="afterLoan.isHit"><i class="el-icon-warning"></i>{{afterLoan.decision}}</label>
            <label class="success" v-else><i class="el-icon-success"></i>{{afterLoan.decision}}</label>
          </li>
        </template>
        <li class="no-ordinance" v-else>暂无数据</li>
      </ul>
    </div>
  </section>
</template>

<script>
import { dealNull } from "@/filters/index";

export default {
  name: "RiskHit",
  filters: { dealNull },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.risk-hit {
  .hit-2 {
    margin: 10px 0;
  }
}
</style>
