import echarts from "echarts";

/** 绘制仪表盘 */
export const drawDashboard = (dataArr, ratio, scoreColor, dom) => {
  const color = new echarts.graphic.LinearGradient(0, 0, 1, 0, [
    {
      offset: 0.3,
      color: "#158dff", // 0% 处的颜色
    },
    {
      offset: 0.8,
      color: "#435ebe", // 100% 处的颜色
    },
  ]);
  const colorSet = [
    [ratio || 0, color],
    [1, "#e5e5e5"],
  ];
  const dashboardOption = {
    series: [
      {
        type: "gauge",
        radius: '80%',
        center: ['50%', '55%'],
        startAngle: "225",
        endAngle: "-45",
        min: 0,
        max: 1000,
        pointer: { show: false },
        title: {
          show: true,
          color: "#1f79b6",
          offsetCenter: ["0", -22],
          fontSize: 16,
        },
        detail: {
          formatter: function (value) {
            var num = Math.round(value);
            return "{scroe|" + num + "}{unit|分}";
          },
          rich: {
            scroe: {
              fontSize: 40,
              fontWeight: "800",
              color: scoreColor || "#29d",
            },
            unit: {
              fontSize: 16,
              color: "#333",
            },
          },
          offsetCenter: ["0%", "20%"],
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: colorSet,
            width: 26,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            opacity: 1,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          show: false,
          length: 25,
          lineStyle: {
            color: "#00377a",
            width: 2,
            type: "solid",
          },
        },
        axisLabel: {
          show: false,
          color: "#29d",
          fontSize: 14,
          formatter: function (value) {
            if (value === 0 || value === 1000) {
              return value;
            }
          },
        },
        data: dataArr,
      },
    ],
  };
  // 绘制
  echarts.init(dom).dispose(); // 创建之前销毁实例
  const Chart = echarts.init(dom);
  Chart.setOption(dashboardOption);
  window.addEventListener("resize", () => {
    Chart.resize();
  });
};
