<template>
  <section class="risk-result">
    <div class="sec-box result-lf">
      <p class="desc" :class="moduleInfos.resClass">
        {{ moduleInfos.resDesc | dealNull }}
      </p>
      <p>来自星图风控建议</p>
    </div>
    <div class="sec-box result-rg" v-if="moduleInfos.numDatas">
      <p v-for="(numOpt, idx1) in moduleInfos.numDatas" :key="`num-${idx1}`">
        <span class="num-data"
          ><strong>{{ numOpt.value | numberToCurrency }}</strong
          >{{ numOpt.unit }}</span
        >
        <span>{{ numOpt.label }}</span>
      </p>
    </div>
  </section>
</template>

<script>
import { dealNull, numberToCurrency } from "@/filters/index";

export default {
  name: "RiskResult",
  filters: { dealNull, numberToCurrency },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.risk-result {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    height: 160px;
    font-size: 16px;
  }
  .result-lf {
    width: calc(26% - 10px);
    padding-top: 42px;
    text-align: center;
    .desc {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .result-rg {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 74%;
    p {
      flex: 1;
      text-align: center;
      & > span {
        display: block;
      }
    }
  }
}
</style>
