var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "credit-portrait-shop" }, [
    _c("div", { staticClass: "portrait-1" }, [
      _c("div", { staticClass: "sec-box portrait-lf" }, [
        _c("div", {
          ref: "chartDashboardRef",
          staticClass: "canvas-container",
        }),
      ]),
      _c("div", { staticClass: "sec-box portrait-rg" }, [
        _c("p", [
          _c("label", { staticClass: "num-data" }, [
            _c("strong", [
              _vm._v(
                _vm._s(
                  _vm._f("numberToCurrency")(_vm.moduleInfos.monthAveragePay, 0)
                )
              ),
            ]),
            _vm._v("元\n        "),
          ]),
          _c("label", [_vm._v("30日均回款金额")]),
        ]),
        _c("p", [
          _c("label", { staticClass: "num-data" }, [
            _c("strong", [
              _vm._v(
                _vm._s(_vm._f("numberToCurrency")(_vm.moduleInfos.salesAmt))
              ),
            ]),
            _vm._v("万元\n        "),
          ]),
          _c("label", [_vm._v("待结算金额")]),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "portrait-2" },
      _vm._l(_vm.moduleInfos.cardList, function (card, idx1) {
        return _c(
          "div",
          { key: `card-${idx1}`, staticClass: "sec-box info-card" },
          [
            _c("p", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm._f("dealNull")(card.name))),
            ]),
            _c("p", { staticClass: "desc" }, [
              _vm._v(_vm._s(_vm._f("dealNull")(card.desc))),
            ]),
            _c("p", { staticClass: "value" }, [
              _vm._v("\n        " + _vm._s(_vm._f("dealNull")(card.value))),
              card.unit ? _c("span", [_vm._v(_vm._s(card.unit))]) : _vm._e(),
            ]),
          ]
        )
      }),
      0
    ),
    _c("div", { staticClass: "portrait-3" }, [
      _c("div", {
        ref: "chartlineRef1",
        staticClass: "sec-box canvas-container",
      }),
      _c("div", {
        ref: "chartlineRef2",
        staticClass: "sec-box canvas-container",
      }),
      _c("div", {
        ref: "chartlineRef3",
        staticClass: "sec-box canvas-container",
      }),
      _c("div", {
        ref: "chartlineRef4",
        staticClass: "sec-box canvas-container",
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }