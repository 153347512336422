var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "credit-report",
    },
    [
      _c("section", { staticClass: "overview" }, [
        _c("div", { staticClass: "report-log" }),
        _c("h1", [
          _vm._v(
            _vm._s(_vm._f("dealNull")(_vm.overviewInfos.shopName)) +
              "店铺综合评估报告"
          ),
        ]),
        _c("h3", [_vm._v("必米数科星图风控平台")]),
        _c("ul", { staticClass: "view-infos" }, [
          _c("li", [
            _c("label", [_vm._v("企业名称：")]),
            _vm._v(_vm._s(_vm._f("dealNull")(_vm.overviewInfos.entName))),
          ]),
          _c("li", [
            _c("label", [_vm._v("店铺ID：")]),
            _vm._v(_vm._s(_vm._f("dealNull")(_vm.overviewInfos.shopId))),
          ]),
          _c("li", [
            _c("label", [_vm._v("所属平台：")]),
            _vm.overviewInfos.platform == 10
              ? _c("span", [_vm._v("抖音")])
              : _vm._e(),
            _vm.overviewInfos.platform == 11
              ? _c("span", [_vm._v("快手")])
              : _vm._e(),
            _vm.overviewInfos.platform == 12
              ? _c("span", [_vm._v("拼多多")])
              : _vm._e(),
            _vm.overviewInfos.platform == 13
              ? _c("span", [_vm._v("腾讯视频号")])
              : _vm._e(),
            _vm.overviewInfos.platform == 14
              ? _c("span", [_vm._v("美团")])
              : _vm._e(),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "main" },
        [
          _c("p", { staticClass: "module-title" }, [
            _vm._v("店铺风控评估结果"),
          ]),
          !_vm.isLoading
            ? _c("RiskResult", { attrs: { moduleInfos: _vm.riskResultInfos } })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [
            _vm._v("店铺信息验证情况"),
          ]),
          !_vm.isLoading
            ? _c("InfoVerify", { attrs: { moduleInfos: _vm.verifyResInfos } })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [
            _vm._v("店铺授信评分情况"),
          ]),
          !_vm.isLoading
            ? _c("CreditScore", {
                attrs: { moduleInfos: _vm.creditScoreInfos },
              })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [
            _vm._v("企业信用画像信息"),
          ]),
          !_vm.isLoading
            ? _c("CreditPortraitEnt", {
                attrs: { moduleInfos: _vm.creditPortraitEntInfos },
              })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [
            _vm._v("店铺信用画像信息"),
          ]),
          !_vm.isLoading
            ? _c("CreditPortraitShop", {
                attrs: { moduleInfos: _vm.creditPortraitShopInfos },
              })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [_vm._v("反欺诈信息")]),
          !_vm.isLoading
            ? _c("AntiFraud", { attrs: { moduleInfos: _vm.antiFraudInfos } })
            : _vm._e(),
          _c("p", { staticClass: "module-title" }, [_vm._v("规则集命中情况")]),
          !_vm.isLoading
            ? _c("RiskHit", { attrs: { moduleInfos: _vm.riskHitInfos } })
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }