<template>
  <section class="sec-box info-verify">
    <div
      class="verify-item"
      v-for="(verify, idx2) in moduleInfos"
      :key="`verify-${idx2}`"
    >
      <div class="icon"><i :class="verify.icon"></i></div>
      <p class="tips">
        <span>{{ verify.labelName1 }}</span>
        <span>{{ verify.labelName2 }}</span>
      </p>
      <p :class="verify.statusClass">
        验证结果：{{ verify.verifyDesc | dealNull }}
      </p>
    </div>
  </section>
</template>

<script>
import { dealNull } from "@/filters/index";

export default {
  name: "InfoVerify",
  filters: { dealNull },
  props: {
    moduleInfos: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.info-verify {
  padding: 20px 0;
  .verify-item {
    display: inline-block;
    width: 33.33%;
    height: 200px;
    box-sizing: border-box;
    padding: 16px;
    text-align: center;
  }
  .icon {
    width: 80px;
    height: 80px;
    line-height: 105px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 8px;
    i {
      font-size: 50px;
    }
  }
  .tips {
    margin-bottom: 10px;
    span {
      display: block;
    }
  }
}
</style>
