<template>
  <section class="credit-portrait-shop">
    <div class="portrait-1">
      <div class="sec-box portrait-lf">
        <div ref="chartDashboardRef" class="canvas-container"></div>
      </div>
      <div class="sec-box portrait-rg">
        <p>
          <label class="num-data">
            <strong>{{
              moduleInfos.monthAveragePay | numberToCurrency(0)
            }}</strong>元
          </label>
          <label>30日均回款金额</label>
        </p>
        <p>
          <label class="num-data">
            <strong>{{ moduleInfos.salesAmt | numberToCurrency }}</strong>万元
          </label>
          <label>待结算金额</label>
        </p>
      </div>
    </div>

    <div class="portrait-2">
      <div
        class="sec-box info-card"
        v-for="(card, idx1) in moduleInfos.cardList"
        :key="`card-${idx1}`"
      >
        <p class="name">{{ card.name | dealNull }}</p>
        <p class="desc">{{ card.desc | dealNull }}</p>
        <p class="value">
          {{ card.value | dealNull
          }}<span v-if="card.unit">{{ card.unit }}</span>
        </p>
      </div>
    </div>

    <div class="portrait-3">
      <div ref="chartlineRef1" class="sec-box canvas-container"></div>
      <div ref="chartlineRef2" class="sec-box canvas-container"></div>
      <div ref="chartlineRef3" class="sec-box canvas-container"></div>
      <div ref="chartlineRef4" class="sec-box canvas-container"></div>
    </div>
  </section>
</template>

<script>
import echarts from "echarts";
import { dealNull, numberToCurrency } from "@/filters/index";
import { drawDashboard } from "../service/too";

export default {
  name: "CreditPortraitShop",
  filters: { dealNull, numberToCurrency },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  watch: {},
  created() {},
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        // 左边-信誉分
        const { score } = this.moduleInfos;
        const value = score || 0;
        const ratio = value / 1000;
        const dataA = [{ value, name: "店铺信用评分" }];
        drawDashboard(dataA, ratio, "", this.$refs.chartDashboardRef);
        // 下面-折线图
        const {
          lineChartData1,
          lineChartData2,
          lineChartData3,
          lineChartData4,
        } = this.moduleInfos;
        const chartData1 = this.dealChartData(lineChartData1)
        const chartData2 = this.dealChartData(lineChartData2)
        const chartData3 = this.dealChartData(lineChartData3)
        const chartData4 = this.dealChartData(lineChartData4)
        const chartOpt1 = this.setChartOption('店铺30日-回款金额波动性', '金额（元）', chartData1.xAxisDatas, chartData1.seriesData);
        const chartOpt2 = this.setChartOption('店铺每日订单交易额波动性', '金额（元）', chartData2.xAxisDatas, chartData2.seriesData);
        const chartOpt3 = this.setChartOption('店铺每日退货率波动性', '退货率（%）', chartData3.xAxisDatas, chartData3.seriesData);
        const chartOpt4 = this.setChartOption('店铺每日揽件率波动性', '揽件率（%）', chartData4.xAxisDatas, chartData4.seriesData);
        const dom1 = this.$refs.chartlineRef1;
        const dom2 = this.$refs.chartlineRef2;
        const dom3 = this.$refs.chartlineRef3;
        const dom4 = this.$refs.chartlineRef4;
        echarts.init(dom1).dispose(); // 创建之前销毁实例
        echarts.init(dom2).dispose();
        echarts.init(dom3).dispose();
        echarts.init(dom4).dispose();
        const Chart1 = echarts.init(dom1);
        Chart1.setOption(chartOpt1);
        const Chart2 = echarts.init(dom2);
        Chart2.setOption(chartOpt2);
        const Chart3 = echarts.init(dom3);
        Chart3.setOption(chartOpt3);
        const Chart4 = echarts.init(dom4);
        Chart4.setOption(chartOpt4);
        window.addEventListener("resize", () => {
          Chart1.resize();
          Chart2.resize();
          Chart3.resize();
          Chart4.resize();
        });
      });
    },
    dealChartData(lineChartData) {
      let [xAxisDatas, seriesData] = [[], []];
      if (lineChartData && lineChartData.length) {
        for (let i = 0; i < lineChartData.length; i++) {
          xAxisDatas.push(lineChartData[i].label);
          seriesData.push(lineChartData[i].value);
        }
      }
      return { xAxisDatas, seriesData };
    },

    // 设置echarts
    setChartOption(name, typeName, xAxisData, seriesData) {
      if (seriesData && seriesData.length) {
        return {
          title: {
            text: name,
            textStyle: {
              align: "center",
              color: "#333",
              fontSize: 18,
            },
            left: "center",
            top: "3%",
          },
          tooltip: { trigger: "axis" },
          grid: {
            top: "26%",
            right: "3%",
            left: "12%",
            bottom: "12%",
          },
          xAxis: {
            type: "category",
            boundaryGap: true, //坐标轴两边留白
            axisLine: {
              lineStyle: {
                color: "#29d",
              },
            },
            axisLabel: {
              color: "#29d",
              textStyle: {
                fontSize: 14,
                color: "#333",
              },
            },
            axisTick: { show: false },
            data: xAxisData,
          },
          yAxis: [
            {
              type: "value",
              name: typeName,
              nameTextStyle: {
                fontSize: 14,
                color: "#333",
              },
              splitNumber: 5,
              axisLabel: {
                formatter: "{value}",
                color: "#333",
                fontSize: 14,
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#29d",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#ccc",
                  type: "dashed",
                },
              },
            },
          ],
          series: [
            {
              name: name,
              type: "line",
              symbol: "circle",
              showSymbol: false,
              symbolSize: 8,
              itemStyle: {
                normal: {
                  color: "#158dff",
                  lineStyle: {
                    color: "#158dff",
                    width: 2,
                  },
                  areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                      {
                        offset: 0.3,
                        color: "rgba(65,159,255,0.3)",
                      },
                      {
                        offset: 0.6,
                        color: "#158dff",
                      },
                      {
                        offset: 1,
                        color: "#435ebe",
                      },
                    ]),
                  },
                },
              },
              data: seriesData,
            },
          ],
        };
      } else {
        return {
          id: Math.random()*100,
          title: {
            text: name,
            subtext: '暂无数据',
            x: "center",
            y: "center",
            textStyle: {
              fontSize: 26,
              color: "#666",
              fontWeight: "normal",
            },
            subtextStyle: {
              fontSize: 16,
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-portrait-shop {
  .portrait-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    & > div {
      height: 260px;
      font-size: 16px;
    }
    .portrait-lf {
      width: calc(45% - 10px);
      .canvas-container {
        width: 100%;
        height: 260px;
      }
    }
    .portrait-rg {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 55%;
      p {
        width: 50%;
        text-align: center;
      }
      .num-data strong {
        color: #e6a23c;
      }
      label {
        display: block;
      }
    }
  }
  .portrait-2 {
    .info-card {
      width: calc(20% - 10px);
    }
  }
  .portrait-3 {
    .canvas-container {
      display: inline-block;
      width: calc(50% - 10px);
      height: 260px;
      margin: 5px;
      margin-bottom: 0;
    }
  }
}
</style>
