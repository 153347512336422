var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "risk-result" }, [
    _c("div", { staticClass: "sec-box result-lf" }, [
      _c("p", { staticClass: "desc", class: _vm.moduleInfos.resClass }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm._f("dealNull")(_vm.moduleInfos.resDesc)) +
            "\n    "
        ),
      ]),
      _c("p", [_vm._v("来自星图风控建议")]),
    ]),
    _vm.moduleInfos.numDatas
      ? _c(
          "div",
          { staticClass: "sec-box result-rg" },
          _vm._l(_vm.moduleInfos.numDatas, function (numOpt, idx1) {
            return _c("p", { key: `num-${idx1}` }, [
              _c("span", { staticClass: "num-data" }, [
                _c("strong", [
                  _vm._v(_vm._s(_vm._f("numberToCurrency")(numOpt.value))),
                ]),
                _vm._v(_vm._s(numOpt.unit)),
              ]),
              _c("span", [_vm._v(_vm._s(numOpt.label))]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }