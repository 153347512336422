<template>
  <section class="credit-score">
    <div class="sec-box score-lf">
      <div ref="chartDashboardRef" class="canvas-container"></div>
    </div>
    <div class="sec-box score-rg">
      <div ref="chartRadarRef" class="canvas-container"></div>
    </div>
  </section>
</template>

<script>
import echarts from "echarts";
import { dealNull } from "@/filters/index";
import { drawDashboard } from "../service/too";

export default {
  name: "CreditScore",
  filters: { dealNull },
  props: {
    moduleInfos: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      legendNames: [
        "店铺特质评估",
        "店铺运营指标",
        "三方数据指标",
        "履约历史评估",
        "反欺诈评估",
        "企业特质评估",
      ],
      chartIndicator: [
        { name: "店铺特质评估", max: 1000 },
        { name: "店铺运营指标", max: 1000 },
        { name: "三方数据指标", max: 1000 },
        { name: "履约历史评估", max: 1000 },
        { name: "反欺诈评估", max: 1000 },
        { name: "企业特质评估", max: 1000 },
      ],
      areaColors: ["#a8bafc", "#879dee", "#6e87e0", "#5570d1", "#435ebe"],
      chartOption: {},
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      this.$nextTick(() => {
        // 左边-信誉分
        const { score } = this.moduleInfos;
        const value = score || 0;
        const ratio = value / 1000;
        const scoreColor =
          value >= 800 ? "#58c820" : value < 600 ? "#c00" : "#29d";
        const dataA = [{ value, name: "店铺授信综合评分" }];
        drawDashboard(dataA, ratio, scoreColor, this.$refs.chartDashboardRef);
        // 右边
        this.setChartOption();
        const dom = this.$refs.chartRadarRef;
        echarts.init(dom).dispose(); // 创建之前销毁实例
        const Chart = echarts.init(dom);
        Chart.setOption(this.chartOption);
        window.addEventListener("resize", () => {
          Chart.resize();
        });
      });
    },

    // 设置echarts
    setChartOption() {
      const { chartData } = this.moduleInfos;
      if (chartData && chartData.length) {
        const dataname = this.legendNames;
        let contains = function (arrays, obj) {
          var i = arrays.length;
          while (i--) {
            if (arrays[i] === obj) {
              return i;
            }
          }
          return false;
        };
        // 配置图表参数
        this.chartOption = {
          tooltip: { show: false },
          radar: {
            center: ["50%", "50%"],
            radius: "65%",
            startAngle: 90,
            splitNumber: 5,
            splitArea: {
              areaStyle: { color: this.areaColors },
            },
            axisLabel: { show: false },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#667dd0",
                width: 2,
              },
            },
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: "#667dd0", // 分隔线颜色
                width: 1, // 分隔线线宽
              },
            },
            name: {
              formatter: function (value) {
                let i = contains(dataname, value);
                let name = dataname[i];
                let text = "";
                if (name === dataname[0]) {
                  text = "{a1|" + name + "}";
                } else if (name === dataname[1]) {
                  text = "{b1|" + name + "}";
                } else if (name === dataname[2]) {
                  text = "{c1|" + name + "}";
                } else if (name === dataname[3]) {
                  text = "{d1|" + name + "}";
                } else if (name === dataname[4]) {
                  text = "{e1|" + name + "}";
                } else {
                  text = "{f1|" + name + "}";
                }
                return text;
              },
              textStyle: {
                rich: {
                  //店铺特质评估
                  a1: {
                    fontSize: 14,
                    color: "#2e9fdf",
                    align: "center",
                  },
                  //店铺运营指标
                  b1: {
                    fontSize: 14,
                    color: "#01A8A1",
                    align: "center",
                  },
                  //三方数据指标
                  c1: {
                    fontSize: 14,
                    color: "#129200",
                    align: "center",
                  },
                  //履约历史评估
                  d1: {
                    fontSize: 14,
                    color: "#FDB400",
                    align: "center",
                  },
                  //反欺诈评估
                  e1: {
                    fontSize: 14,
                    color: "#FD6C00",
                    align: "center",
                    padding: 4,
                  },
                  //企业特质评估
                  f1: {
                    fontSize: 14,
                    color: "#932fd7",
                    align: "center",
                    padding: 4,
                  },
                },
              },
            },
            indicator: this.chartIndicator,
          },
          series: [
            {
              type: "radar",
              symbol: "circle",
              symbolSize: 7,
              areaStyle: {
                normal: {
                  color: "rgba(170, 216, 255, 0.65)",
                },
              },
              itemStyle: {
                color: "#84E1FF",
                borderColor: "#059ff7",
                borderWidth: 1,
              },
              data: [chartData],
            },
          ],
        };
      } else {
        this.chartOption = {
          id: "1",
          title: {
            text: "暂无数据",
            x: "center",
            y: "center",
            textStyle: {
              fontSize: 26,
              color: "#666",
              fontWeight: "normal",
            },
          },
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.credit-score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    height: 260px;
    font-size: 16px;
  }
  .score-lf {
    width: calc(45% - 10px);
    .canvas-container {
      width: 100%;
      height: 260px;
    }
  }
  .score-rg {
    width: 55%;
    .canvas-container {
      width: 80%;
      height: 260px;
      margin: 0 auto;
    }
  }
}
</style>
