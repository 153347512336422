var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "sec-box info-verify" },
    _vm._l(_vm.moduleInfos, function (verify, idx2) {
      return _c("div", { key: `verify-${idx2}`, staticClass: "verify-item" }, [
        _c("div", { staticClass: "icon" }, [_c("i", { class: verify.icon })]),
        _c("p", { staticClass: "tips" }, [
          _c("span", [_vm._v(_vm._s(verify.labelName1))]),
          _c("span", [_vm._v(_vm._s(verify.labelName2))]),
        ]),
        _c("p", { class: verify.statusClass }, [
          _vm._v(
            "\n      验证结果：" +
              _vm._s(_vm._f("dealNull")(verify.verifyDesc)) +
              "\n    "
          ),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }